// EventCountdown.tsx

import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface EventCountdownProps {
  currentState: string;
  selectedGameweek: string; // Add selectedGameweek prop
  onGameweekChange: (gameweek: string) => void;
}

const EventCountdown: React.FC<EventCountdownProps> = ({
  currentState,
  selectedGameweek,
  onGameweekChange,
}) => {
  const [timeLeft, setTimeLeft] = useState('');
  const [gameweeks, setGameweeks] = useState<string[]>([]);
  const { t } = useTranslation();

  const today = new Date().getDay(); // Returns a number from 0 (Sunday) to 6 (Saturday)

const isMondayToThursday = today >= 1 && today <= 4; // True if between Monday (1) and Thursday (4)


  const currentHostname = window.location.href;
  const stagingKey = process.env.REACT_APP_KEY_STAGING || 'staging2';
  const linkUrl = currentHostname.includes(`${stagingKey}`)
    ? `https://${stagingKey}.peaxel.me`
    : 'https://peaxel.me';

  useEffect(() => {
    const fetchGameweeks = async () => {
      try {
        const response = await axios.get<string[]>(`${linkUrl}/wp-json/custom/v1/get-gameweeks`);
        setGameweeks(response.data);

        // Set the latest gameweek as the initial value
        if (response.data.length > 0 && !selectedGameweek) {
          const latestGameweek = response.data[0];
          onGameweekChange(latestGameweek);
        }
      } catch (error) {
        console.error('Error fetching gameweeks:', error);
      }
    };

    fetchGameweeks();
  }, [linkUrl, onGameweekChange, selectedGameweek]);

  const handleGameweekChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    onGameweekChange(event.target.value);
  };

  useEffect(() => {
    if (selectedGameweek !== gameweeks[0]) {
      setTimeLeft('');
      return;
    }




    const calculateEventDate = () => {
      const now = new Date();
      let eventDate = new Date();
      const today = new Date().getDay();

      if (today >= 1 && today <= 4) {
        eventDate.setDate(now.getDate() + (4 - today));
        eventDate.setHours(23, 59, 0, 0);
      } else {
        eventDate.setDate(now.getDate() + (0 === today ? 0 : 7 - today));
        eventDate.setHours(23, 0, 0, 0);
      }
      return eventDate;
    };

    const startCountdown = () => {
      const eventDate = calculateEventDate();

      const updateCountdown = () => {
        const now = new Date().getTime();
        const eventTime = eventDate.getTime();
        const difference = eventTime - now;

        if (difference <= 0) {
          setTimeLeft(t('Event has started!'));
          return;
        }

        const days = Math.floor(difference / (1000 * 60 * 60 * 24));
        const hours = Math.floor((difference / (1000 * 60 * 60)) % 24);
        const minutes = Math.floor((difference / (1000 * 60)) % 60);
        const seconds = Math.floor((difference / 1000) % 60);

        setTimeLeft(`${days}${t('d')} ${hours}${t('h')} ${minutes}${t('m')} ${seconds}${t('s')}`);
      };

      updateCountdown();
      const intervalId = setInterval(updateCountdown, 1000);
      return () => clearInterval(intervalId);
    };

    startCountdown();
  }, [selectedGameweek, gameweeks, t]);

  const currentDate = new Date();
  const currentYear = currentDate.getFullYear();
  const currentWeek = String(getWeekNumber(currentDate)).padStart(2, '0');
  const mainGameweek = `${currentYear}-${currentWeek}`;

  // Helper function to get week number
function getWeekNumber(date: Date): number {
const firstDayOfYear = new Date(date.getFullYear(), 0, 1);
const pastDaysOfYear = (date.getTime() - firstDayOfYear.getTime()) / 86400000;
return Math.ceil((pastDaysOfYear + firstDayOfYear.getDay() + 1) / 7);
}

  return (
    <div>
      
    {(currentState === 'results') && (
      <>
    <div className='countdown-competition'>
      <span style={{ fontWeight: '200', fontSize: '50%' }}>Game Week</span><br />
      {currentState === 'results' && (
        <select
          value={selectedGameweek}
          onChange={handleGameweekChange}
          className="selectGameWeek"
        >
          {gameweeks.map((gameweek, index) => (
            <option key={index} value={gameweek}>
              {gameweek}
            </option>
          ))}
        </select>
      )}


      {selectedGameweek === gameweeks[0] && timeLeft && (
        <div className='countdown-team'>
        <span style={{ fontWeight: '500', fontSize: '100%' }}>
          {t('lineupClosure')}
          <br />
          {timeLeft}
        </span>
        </div>
      )}
    </div>
    </>
    )}


{(currentState === 'myLineup') && (
      <>
      
      <div className='countdown-team'>

      <span style={{ fontWeight: '100', marginBottom: '15px' }}>Game Week</span>
      
      <span className='noSelectGameWeek'>
      {mainGameweek}
      </span>
  
     
      <span className='countdown-team2'>
      {isMondayToThursday ? t('lineupClosure') : t('gameWeekClosure')} {timeLeft}
      </span>

    </div>
    </>
    )}









    </div>
  );
};

export default EventCountdown;
